import { HiddenPricingPage } from './hidden-pricing-page';

export function HiddenPricingPageWrapper1() {
  return <HiddenPricingPage targetId="34" />;
}

export function HiddenPricingPageWrapper2() {
  return <HiddenPricingPage targetId="36" />;
}

export function HiddenPricingPageWrapper3() {
  return <HiddenPricingPage targetId="37" />;
}

export function HiddenPricingPageWrapper4() {
  return <HiddenPricingPage targetId="46" />;
}

export function HiddenPricingPageWrapper5() {
  return <HiddenPricingPage targetId="43" />;
}
